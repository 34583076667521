<template>
    <el-cascader :options="options" clearable @change="change" v-model="option" />
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import { regionData } from 'element-china-area-data' //CodeToText, TextToCode 

const emit = defineEmits(["success"])
const props = defineProps(["defaultOption"])

const options = ref('')
const option = ref('')

options.value = regionData
option.value = props.defaultOption ? props.defaultOption : ''

const change = (value) => {
    emit("success", value)
}

</script>